import defaultHtmlSerializer from "storyblok-js-client/source/schema.js";
export const INTERCOM_APP_ID = "i9m4o3sh";
export const WEB_APP_SIGN_UP_LINK = "https://app.airgram.io/signup";
export const WEB_APP_LOGIN_UP_LINK = "https://app.airgram.io/login";
export const HOST_URL = "https://www.airgram.io"

// seo
export const seoKeyMap = {
  "Seo Title": "seoTitle",
  "Seo Description": "seoDesc",
};

// hero
export const heroKeyMap = {
  head_title: "heroTitle",
  head_paragraph: "heroParagraph",
  button: "heroButton",
  cover: "heroCover",
};
// custom cms interface END

export const LOADING_IMAGE_URL =
  "https://a.storyblok.com/f/167495/x/d84f791ac8/animation-blog-collection-image-loading.svg";

export const isEmailLinkType = (type) => type === "email";

export const richTextSchema = {
  ...Object.assign(defaultHtmlSerializer, {
    marks: {
      ...Object.assign(defaultHtmlSerializer.marks, {
        link(node) {
          const attrs = { ...node.attrs };
          const { linktype = "url" } = node.attrs;

          if (isEmailLinkType(linktype)) {
            attrs.href = `mailto:${attrs.href}`;
          }

          if (attrs.anchor) {
            attrs.href = `${attrs.href}#${attrs.anchor}`;
            delete attrs.anchor;
          }

          if (attrs.custom) {
            for (const key in attrs.custom) {
              attrs[key] = attrs.custom[key];
            }
            delete attrs.custom;
          }

          return {
            tag: [
              {
                tag: "a",
                attrs: attrs,
              },
            ],
          };
        },
      }),
    },
  }),
};
