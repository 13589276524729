import { graphql, useStaticQuery } from "gatsby";
import Layout from "@layout/index";
import BlogList from "@/cms/blog/blog-list";
import Header from "@/components/head";
import * as React from "react";

import BottomLayout from "@/layout/BottomLayout";

import { Box, Button, Center, Text, Image } from "@chakra-ui/react";
import LoadingIcon from "@/assets/images/BlogLoading.svg";

export const Head = () => {
  return (
    <Header
      title="Airgram Blog | Virtual Meeting Productivity Made Easy"
      description="Online meeting productivity, note-taking hacks, workflow, automations - Airgram blog posts keep you supercharged. The content hub for virtual meetings."
    />
  )
}

const { useState } = React;

const BlogPage = ({ data }) => {
  const maxLen = data.allStoryblokEntry.nodes.length;
  let storageCurPageNum = 1;
  if (typeof window !== 'undefined') {
    storageCurPageNum = Number(window.sessionStorage.getItem('blogCurPageNum')) || 1;
  }
  const [curPageNum, setCurPageNum] = useState(storageCurPageNum);
  const [loading, setLoading] = useState(false);
  const pageSize = 9;
  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setCurPageNum(curPageNum + 1);
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('blogCurPageNum', String(curPageNum + 1));
      }
      setLoading(false);
    }, 200);
  }

  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_blog"
          buttonId="blog_bottom_cta"
          description="Level up your Zoom, Google Meet & Microsoft Teams meeting productivity"
          buttonText="Try for free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <Center flexDir="column">
        <BlogList data={data} blogSum={pageSize * curPageNum} />
        <Center
          w="full"
          mt={{
            base: "24px",
            sm: "40px",
            md: "40px",
            lg: "40px",
            xl: "40px",
            "2xl": "40px"
          }}
          mb={{
            base: "40px",
            sm: "64px",
            md: "80px",
            lg: "120px",
            xl: "120px",
            "2xl": "120px"
          }}
        >
          {
            curPageNum * pageSize >= maxLen ?
              <Text textAlign="center" color="#7D848E">
                🥳 Congratulations, you have reached the end.
              </Text> : (
                loading ?
                  <Box p="14px" bg="#FBF0FF" borderRadius="8px">
                    <Image src={LoadingIcon} className="blog-loading" />
                  </Box> : <Text
                    as="button"
                    p="12px 24px"
                    bg="#FBF0FF"
                    borderRadius="8px"
                    color="#9F2AEC"
                    _hover={{
                      bg: "#F3D1FF",
                    }}
                    _active={{
                      bg: "#E5A8FF"
                    }}
                    onClick={handleLoadMore}
                    fontWeight="600"
                  >
                    Load more
                  </Text>
              )
          }
        </Center>
      </Center>
    </Layout>
  );
};

export const query = graphql`
  query BlogsQuery {
    allStoryblokEntry(
      filter: { field_component: { eq: "blog" }}
      sort: {fields: published_at, order: DESC}
    ) {
      nodes {
        slug
        full_slug
        content
        tag_list
        id
      }
    }
  }
`;

export default BlogPage;
