import * as React from "react";
import { Button, Center, Text, Link as ChakraLink, Box, useBreakpointValue } from "@chakra-ui/react";

const { useRef, useState, useEffect, useMemo, memo } = React;

export interface IBottomLayoutProps {
  description: string;
  buttonText: string;
  buttonLink: string;
  isPricing?: boolean;
  buttonId?: string;
  signGtmId?: string;
}

export const BottomLayout: React.FC<IBottomLayoutProps> = (props) => {
  const { description, buttonText, buttonLink, isPricing, buttonId = "", signGtmId } = props;
  const PRICING_CTA_BG = useBreakpointValue(
    {
      base: "bottom-layout-bg-no-img",
      sm: "bottom-layout-bg",
      md: "bottom-layout-bg",
      lg: "bottom-layout-bg",
      xl: "bottom-layout-bg",
      "2xl": "bottom-layout-bg",
    },
    {
      fallback: "md",
    }
  )
  return (
    <Center
      py="80px"
      px={{ base: "12px", md: "40px" }}
      width="full"
      flexDirection="column"
      borderRadius="20px"
      className={isPricing ? PRICING_CTA_BG : "bottom-layout-bg-no-img"}
      mb={{
        base: "40px",
        sm: "64px",
        md: "80px",
        lg: "120px",
        xl: "120px",
        "2xl": "120px",
      }}
    >
      <Text
        as="h2"
        color="#220247"
        margin="8px 0px"
        w="80%"
        maxWidth="960px"
        textAlign="center"
      >
        {description}
      </Text>

      <Box className={isPricing ? 'bottom-layout-button' : ''} pt="32px">
        <ChakraLink
          id={buttonId}
          href={buttonLink}
          target="_blank"
          _hover={{ textDecor: "none" }}
          lineHeight="52px"
        >
          <Button data-gtm={signGtmId} size="lg" colorScheme="brand">
            {buttonText}
          </Button>
        </ChakraLink>
      </Box>
    </Center>
  );
};
export default memo(BottomLayout);
